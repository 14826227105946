import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {deleteHelper, getHelper, postHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";
import {
	attendantsAllowed, calculateRemainingPlaces,
	filterFutureSlots,
	getDate,
	getQueryParam,
} from "../../general/util";

export const fetchSlots = createAsyncThunk(
	'bookings/fetchSlots',
	async ({accommodation, outlet, selectedDate}, {rejectWithValue}) => {
		const bookingTimeThreshold = outlet?.richTags?.find(richTag => richTag.richTagName === 'bookingTimeThreshold')?.richTag?.bookingTimeThreshold;
		try {
			const response = await getHelper(`${BASE_URL}/ea/guest/slots?providerId=${outlet.providerId}&serviceId=${outlet.serviceId}&date=${selectedDate}`, {
				authorization: `lid ${accommodation}`,
			});
			if (!response.length) {
				rejectWithValue('Not available slots')
			}
			
			if (response[0]?.slots?.length) {
				const futureSlots = filterFutureSlots(response[0]?.slots, true, selectedDate, bookingTimeThreshold); // true indicates slots are objects
				const remainingPlaces = calculateRemainingPlaces(futureSlots[0], response[0].service);
				return {
					...response[0],
					selectedTime: futureSlots[0].start || null,
					selectedSlot: {...futureSlots[0], remainingPlaces },
					slots: futureSlots,
					selectedOutlet: outlet,
				};

			}
			
			if (response[0]?.flexibleSlots) {
				const availableFlexibleSlots = filterFutureSlots(response[0].flexibleSlots, false, selectedDate, bookingTimeThreshold);
				return {
					...response[0],
					selectedTime: availableFlexibleSlots[0] || null,
					slots: availableFlexibleSlots,
					selectedOutlet: outlet,
				}
			}
			return {
				selectedTime: null,
				slots: [],
				selectedOutlet: outlet,
				...response[0]
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchAppointments = createAsyncThunk(
	'bookings/fetchAppointments',
	async ({accommodation, type}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/ea/guest/appointments`,
				{Authorization: `token ${type}`});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error fetching appointments');
		}
	}
);

export const isEntitled = createAsyncThunk(
	'bookings/isEntitled',
	async ({providerId, reservationNumber, startDate}, {rejectWithValue}) => {
		
		if (!providerId || !reservationNumber) {
			return {isEntitled: true}
		}
		try {
			const response = await getHelper(`${BASE_URL}/ea/providers/${providerId}/entitled/${reservationNumber}?startDate=${startDate}`,
				{
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				})
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Guest is not entitled');
		}
	});

export const deleteAppointment = createAsyncThunk(
	'bookings/deleteAppointment',
	async ({accommodation, type, id}, {rejectWithValue}) => {
		try {
			const response = await deleteHelper(`${BASE_URL}/ea/guest/appointments/${id}`,
				{Authorization: `token ${type}`});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error deleting appointment');
		}
	}
);


// TODO: Uncomment below function when implementation on backend for SMS is completed
// export const createAppointment = createAsyncThunk(
// 	'bookings/createAppointment',
// 	async ({token, additionalPayload, isCallBackRequest = false}, {getState, rejectWithValue, dispatch}) => {
// 		try {
// 			const state = getState();
// 			const {
// 				selectedTime,
// 				selectedDate,
// 				selectedOutlet,
// 				adults,
// 				children,
// 				selectedService,
// 				selectedAddOns
// 			} = state.bookings;
// 			const {data} = state.user;
//
// 			const requireSmsConfirmation = selectedOutlet.tags?.includes("requireSmsConfirmation");
//
// 			const canBook = await dispatch(isEntitled({
// 				providerId: selectedOutlet.providerId,
// 				reservationNumber: data?.reservationNumber,
// 				startDate: selectedDate,
// 			})).unwrap();
//
// 			if (!canBook.isEntitled) {
// 				// Guest is not entitled, so reject the thunk
// 				return rejectWithValue('Guest is not entitled');
// 			}
//
// 			let payload = {}
// 			if (isCallBackRequest) {
// 				payload = {
// 					date: getDate.tomorrow(),
// 					time: "09:00",
// 					mobile: additionalPayload.mobile,
// 					specialRequests: additionalPayload.notes,
// 					persons: 1,
// 					restaurant: selectedOutlet?.name?.en,
// 					source: "W",
// 					ea: {
// 						providerId: selectedOutlet.providerId,
// 						serviceId: selectedOutlet.serviceId,
// 					},
// 					integrationId: undefined,
// 					sendSmsConfirmation: requireSmsConfirmation
// 				}
// 			} else {
// 				payload = {
// 					attendantsNumber: Number(adults) + Number(children),
// 					providerId: selectedOutlet?.providerId,
// 					serviceId: selectedOutlet?.serviceId,
// 					start: `${selectedDate} ${selectedTime?.start || selectedTime}`,
// 					source: "W",
// 					notes: `${additionalPayload.notes}` + (selectedAddOns && selectedAddOns.length > 0 ? ` AddOns: ${selectedAddOns.join(', ')}` : ""),
// 					reservationNumber: data?.reservationNumber,
// 					loyaltyId: data?.loyaltyNumber ? data?.id : null,
// 					roomNumber: data?.roomNumber,
// 					sendSmsConfirmation: requireSmsConfirmation,
// 					reservationExtraDetails: selectedService ? {
// 						addOns: selectedAddOns?.toString(),
// 						source: getQueryParam("source") || "",
// 						destination: "",
// 						children: undefined,
// 						price: null,
// 						deposit: null,
// 						status: "tentative",
// 						paymentMethod: "on_arrival"
// 					} : undefined
// 				}
// 			}
//
// 			const url = payload.reservationExtraDetails ? `${BASE_URL}/eaWithExtraDetails/guest/appointments` : isCallBackRequest ? `${BASE_URL}/dinings/${selectedOutlet?.serviceId}` : `${BASE_URL}/ea/guest/appointments`;
// 			const response = await postHelper(`${url}`, payload,
// 				{Authorization: `token ${token}`});
// 			return response;
// 		} catch (error) {
// 			return rejectWithValue(error.message || 'Error creating appointments');
// 		}
// 	}
// );


export const createAppointment = createAsyncThunk(
	'bookings/createAppointment',
	async ({token, additionalPayload, isCallBackRequest = false}, {getState, rejectWithValue, dispatch}) => {
		const state = getState();
		const {
			selectedTime,
			selectedDate,
			selectedOutlet,
			adults,
			children,
			selectedService,
			selectedAddOns,
			selectedAllergies,
			selectedSlot
		} = state.bookings;
		const {data} = state.user;
		
		const requireSmsConfirmation = selectedOutlet.tags?.includes("requireSmsConfirmation");
		
		let canBook;
		try {
			canBook = await dispatch(isEntitled({
				providerId: selectedOutlet.providerId,
				reservationNumber: data?.reservationNumber,
				startDate: selectedDate || getDate.tomorrow(),
			})).unwrap();
		} catch (error) {
			return rejectWithValue(error?.message || 'Failed to check entitlement');
		}
		
		if (!canBook?.isEntitled) {
			return rejectWithValue(canBook?.messageId || canBook?.message || 'Guest is not entitled');
		}
		
		try {
			let response = undefined
			
			const combinedNotes = [
				additionalPayload.notes && `Notes: ${additionalPayload.notes}`,
				selectedAllergies?.length >= 1 && `Allergies: ${selectedAllergies.join(", ")}`
			].filter(Boolean).join(", ");
			
			if (!isCallBackRequest) {
				const appointmentPayload = {
					attendantsNumber: Number(adults) + Number(children),
					providerId: selectedOutlet?.providerId,
					serviceId: selectedOutlet?.serviceId,
					start: `${selectedDate} ${selectedTime?.start || selectedTime}`,
				...( selectedSlot?.area ? {area: {...selectedSlot.area}} : {}),
					source: "W",
					notes: "*W* " + combinedNotes,
					reservationNumber: data?.reservationNumber,
					loyaltyId: data?.loyaltyNumber ? data?.id : null,
					roomNumber: data?.roomNumber,
					sendSmsConfirmation: requireSmsConfirmation,
					reservationExtraDetails: selectedService ? {
						addOns: selectedAddOns?.toString(),
						source: getQueryParam("source") || "",
						destination: "",
						children: undefined,
						price: null,
						deposit: null,
						status: "NEW_RESERVATION",
						paymentMethod: "on_arrival"
					} : undefined
				};
				
				const appointmentUrl = appointmentPayload.reservationExtraDetails ? `${BASE_URL}/eaWithExtraDetails/guest/appointments` : `${BASE_URL}/ea/guest/appointments`;
				const responseAppointment = await postHelper(appointmentUrl, appointmentPayload, {Authorization: `token ${token}`});
				
				response = responseAppointment
				// Check if the appointment was successful (based on response having an ID)
				if (!responseAppointment && (!responseAppointment.id || !responseAppointment.reservationExtraDetails)) {
					return rejectWithValue('Failed to create appointment');
				}
			}
			
			const reservationPayload = {
				date: selectedDate || getDate.tomorrow(),
				time: selectedTime?.start || selectedTime || "09:00",
				mobile: additionalPayload.mobile,
				specialRequests:  combinedNotes,
				persons: Number(adults) + Number(children),
				restaurant: selectedOutlet?.name?.en,
				source: "W",
				ea: {
					providerId: selectedOutlet.providerId,
					serviceId: selectedOutlet.serviceId,
				},
				integrationId: undefined,
				sendSmsConfirmation: requireSmsConfirmation
			};
			
			const reservationUrl = `${BASE_URL}/dinings/${selectedOutlet?.urlId}`;
			const reservationResponse = await postHelper(reservationUrl, reservationPayload, {Authorization: `token ${token}`});
			
			return response || reservationResponse;
		} catch (error) {
			return rejectWithValue(error.message || 'Error creating appointments');
		}
	}
);


const initialState = {
	appointments: [],
	deleteStatus: 'idle',
	status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
	error: null,
	selectedOutlet: null,
	selectedDate: null,
	selectedTime: null,
	selectedSlot: null,
	slots: [],
	slotInfo: {},
	adults: 1,
	children: 0,
	selectedAllergies: [],
	appointmentCreated: false,
	selectedService: null,
	selectedAddOns: [],
	addOnsValidation: {
		isValid: true,
		message: ''
	},
	personsValidation: {
		isValid: true,
		message: ''
	}
}

const bookingsSlice = createSlice({
	name: 'bookings',
	initialState,
	reducers: {
		setSelectedDate: (state, action) => {
			state.selectedDate = action.payload;
		},
		setSelectedTime: (state, action) => {
			const remainingPlaces = action.payload.selectedSlot && calculateRemainingPlaces(action.payload.selectedSlot, state.slotInfo.service);
			state.adults = 1;
			state.children = 0;
			state.selectedTime = action.payload.selectedTime;
			state.selectedSlot = action.payload.selectedSlot ? {...action.payload.selectedSlot, remainingPlaces} : null ;
		},
		setAdults: (state, action) => {
			const newAdults = Math.max(1, action.payload); // Ensure at least 1 adult
			if (attendantsAllowed(
				newAdults,
				state.children,
				state.selectedSlot,
				state.slotInfo.service
			)) {
				state.adults = newAdults;
				state.personsValidation = {isValid: true, message: ''};
			} else {
				state.personsValidation = {
					isValid: false,
					message: "You have exceeded the maximum number of attendants allowed."
				};
			}
		},
		setChildren: (state, action) => {
			const newChildren = Math.max(0, action.payload); // No negative counts
			if (attendantsAllowed(
				state.adults,
				newChildren,
				state.selectedSlot,
				state.slotInfo.service
			)) {
				state.children = newChildren;
				state.personsValidation = {isValid: true, message: ''};
			} else {
				state.personsValidation = {
					isValid: false,
					message: "You have exceeded the maximum number of attendants allowed."
				};
			}
		},
		setSelectedAllergies: (state, action) => {
			state.selectedAllergies = action.payload;
		},
		resetAppointmentCreated: (state) => {
			state.appointmentCreated = false;
		},
		setSelectedService: (state, action) => {
			state.selectedService = action.payload;
		},
		addAddOns: (state, action) => {
			state.selectedAddOns = state.selectedAddOns.includes(action.payload) ? state.selectedAddOns : [...state.selectedAddOns, action.payload]
		},
		removeAddOns: (state, action) => {
			state.selectedAddOns = state.selectedAddOns.filter(name => name !== action.payload)
		},
		setAddOnsValidation: (state, action) => {
			state.addOnsValidation = action.payload;
		},
		setSelectedOutlet: (state, action) => {
			state.selectedOutlet = action.payload
		},
		setClearBookingError: (state, action) => {
			state.error = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAppointments.pending, (state) => {
				state.status = 'loading'
				state.deleteStatus = 'idle';
			})
			.addCase(fetchAppointments.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(fetchAppointments.fulfilled, (state, action) => {
				state.appointments = action.payload;
				state.status = 'succeeded';
				state.error = null;
			})
			.addCase(fetchSlots.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchSlots.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(fetchSlots.fulfilled, (state, action) => {
				// Set the common properties that don't depend on the condition
				state.selectedTime = action.payload?.selectedTime;
				state.selectedSlot = action.payload?.selectedSlot;
				state.slots = action.payload?.slots;
				state.selectedOutlet = action.payload?.selectedOutlet;
				state.slotInfo = {
					...action.payload,
					slots: undefined,
					selectedOutlet: undefined,
					selectedTime: undefined
				};
				state.status = 'succeeded';
				state.error = null;
				
				// Reset adults and children to default
				state.adults = 1;
				state.children = 0;
				
				// Now, conditionally set the validation based on attendants allowed
				const isAttendantsAllowed = attendantsAllowed(
					1, // Assuming this is the default number of adults to check
					0, // Assuming this is the default number of children to check
					action.payload?.selectedSlot,
					action.payload?.service
				);
				
				if (isAttendantsAllowed) {
					state.personsValidation = {isValid: true, message: ''};
				} else {
					state.personsValidation = {
						isValid: false,
						message: "You have exceeded the maximum number of attendants allowed."
					};
				}
			})
			.addCase(isEntitled.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(isEntitled.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(isEntitled.fulfilled, (state, action) => {
				state.isEntitled = action.payload;
				state.status = 'succeeded';
				state.error = null;
			})
			.addCase(createAppointment.pending, (state) => {
				state.status = 'loading'
				state.error = null;
			})
			.addCase(createAppointment.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.appointmentCreated = false;
			})
			.addCase(createAppointment.fulfilled, (state, action) => {
				state.appointments.push(action.payload); // Or handle according to your data structure
				state.appointmentCreated = true; // Set the flag to true on successful appointment creation
				state.status = 'succeeded';
				state.error = null;
			})
			.addCase(deleteAppointment.pending, (state) => {
				state.status = 'loading'
				state.deleteStatus = 'loading'
			})
			.addCase(deleteAppointment.rejected, (state, action) => {
				state.status = 'failed';
				state.deleteStatus = 'failed'
				state.error = action.payload;
			})
			.addCase(deleteAppointment.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.deleteStatus = 'succeeded'
				state.error = null;
			})
	}
});

export const {
	setSelectedDate,
	setSelectedTime,
	setAdults,
	setChildren,
	resetAppointmentCreated,
	setSelectedService,
	addAddOns,
	removeAddOns,
	setAddOnsValidation,
	setSelectedOutlet,
	setClearBookingError,
	setSelectedAllergies
} = bookingsSlice.actions;
export default bookingsSlice.reducer;
